import About from "./pages/about";
import Catalogue from "./pages/catalogue";
import Home from "./pages/home";

const routes = [
  {
    name: "home",
    path: "/",
    component: <Home />,
  },
  {
    name: "about",
    path: "/a-propos",
    component: <About />,
  },
  {
    name: "catalogue",
    path: "/catalogue",
    component: <Catalogue />,
  },
];

export default routes;
