import {
  alpha,
  Box,
  Button,
  Container,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

const HomeBanner = () => {
  const theme = useTheme();
  return (
    <Grid
      container
      spacing={2}
      sx={{
        p: { md: 8, xs: 2 },
        pb: 10,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundImage: `linear-gradient(
        to bottom,
         ${alpha(theme.palette.secondary.main, 0.7)},
         ${alpha(theme.palette.primary.main, 0.2)}
      ), url('/images/banner.jpg')`,
        height: 700,
      }}
    >
      <Grid
        container
        direction={"row"}
        justifyContent={"center"}
        alignItems={"center"}
        item
        sx={{ color: "white" }}
        xs={12}
        md={6}
      >
        <Container>
          <Typography
            variant={"h3"}
            sx={{ mt: 8, fontSize: { xs: 40 }, textTransform: "uppercase" }}
          >
            Le plaisir à bord du confort
          </Typography>

          <Grid sx={{ display: { xs: "flex", md: "none" }, mt: 5 }} container>
            <Grid item xs={6}>
              <Button
                fullWidth
                sx={{ color: "white", borderRadius: 8 }}
                variant="contained"
                href="https://firebasestorage.googleapis.com/v0/b/safari-car.appspot.com/o/rental%2FCatalogue%20Safari%20car%202024.pdf?alt=media&token=cee369cd-926c-4e73-a7d3-db3b9a426e6a"
              >
                Voir le catalogue
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                fullWidth
                sx={{
                  mx: 2,
                  color: "white",
                  borderColor: "white",
                  borderRadius: 8,
                }}
                variant="outlined"
                href="https://bit.ly/3Oz5D8d"
                startIcon={<WhatsAppIcon />}
              >
                Réserver
              </Button>
            </Grid>
          </Grid>

          <Box sx={{ display: { xs: "none", md: "flex", mt: 3 } }}>
            <Button
              component="a"
              sx={{ color: "white", borderRadius: 8 }}
              variant="contained"
              href="https://firebasestorage.googleapis.com/v0/b/safari-car.appspot.com/o/rental%2FCatalogue%20Safari%20car%202024.pdf?alt=media&token=cee369cd-926c-4e73-a7d3-db3b9a426e6a"
            >
              Voir le catalogue
            </Button>
            <Button
              component="a"
              sx={{
                mx: 2,
                color: "white",
                borderColor: "white",
                borderRadius: 8,
              }}
              variant="outlined"
              href="https://bit.ly/3Oz5D8d"
              startIcon={<WhatsAppIcon />}
            >
              Nous contacter
            </Button>
          </Box>
        </Container>
      </Grid>
    </Grid>
  );
};

export default HomeBanner;
