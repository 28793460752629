import { Avatar, Container, Grid, Typography, useTheme } from "@mui/material";
import React from "react";

const About = () => {
  const theme = useTheme();
  return (
    <main>
      <Grid direction={"row-reverse"} container spacing={2}>
        <Grid
          container
          direction={"row"}
          justifyContent={"center"}
          alignItems={"center"}
          item
          xs={12}
          md={6}
        >
          <Container>
            <Typography variant="h3" sx={{ my: 2 }}>
              Le Parcours
            </Typography>
            <Typography>
              Depuis près de quatre et demi que nous existons, nous avons appris
              à travailler avec esprit de serviabilité et d'organisation, ce qui
              reflète aujourd'hui le dévouement dans ce service satisfaisant et
              irreprochable que nous offrons pour le bonheur de nos clients.{" "}
              <br />
            </Typography>
            <Typography sx={{ my: 1 }}>
              Si nous avons réussi pendant ces années à marcher pas après un
              autre vers l'amélioration de la qualité de nos services, c'est
              parce qu'au cœur de nos actions nous plaçons les clients et
              faisons de leurs besoins de déplacement notre mission.
            </Typography>
            <Typography>
              Les chiffres, images mais aussi des témoignages de ceux qui ont
              confiance à nos services.
            </Typography>
          </Container>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography>
            <img src="/images/aboutCar.png" width={"100%"} />
          </Typography>
        </Grid>
      </Grid>
      <Container sx={{ p: 5 }}>
        <Typography color={"primary"} textAlign={"center"} variant="h3">
          Qui{" "}
          <span style={{ color: theme.palette.secondary.main }}>
            est derrière
          </span>{" "}
          SafariCar?
        </Typography>
        <Grid sx={{ my: 5 }} container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography textAlign={"center"}>
              <Avatar
                src="/images/gires.jpg"
                sx={{ width: 300, height: 300 }}
              />
            </Typography>
          </Grid>
          <Grid
            container
            direction={"row"}
            justifyContent={"center"}
            alignItems={"center"}
            item
            xs={12}
            md={6}
          >
            <Typography>
              C'est l'histoire d'un petit jeune homme, aujourd'hui devenu
              entrepreneur à succès.
            </Typography>
            <Typography>
              Gires a quitté le Cameroun à l'âge de 21 ans avec espoir de se
              trouver une meilleure vie. Arrivé à Kinshasa où il est contamment
              installé, il travaille pendant un temps comme gérant d'une usine.
              Mais il découvre très rapidement que le système ne le convenait
              pas, et que vivre comme un salarié n'était jamais sur la liste de
              ses ambitions. Ce qui le pousse à démissionner et entamer le
              processus de résilience.
            </Typography>
            <Typography>
              Après plusieurs mois sans emploi, le petit garçon d'hier décide de
              se lancer dans la photographie à Kinshasa avec le projet SAFARI
              Pictures. Malheureusement ou heureusement, ce projet ne connait
              pas des jours roses. Les mois qui ont suivi, SAFARI Pictures
              connait une période difficile et sombre dans la faillite.
            </Typography>
            <Typography>
              Kinshasa compte des npmbreux photographes, il en fallait encore
              beaucoup pour arriver à constituer un carnet d'adresses
              conséquent. Face à la réalité où la concurrence était rude sur le
              marché de la photographie, Gires échoue et arrête le projet avant
              de trouver sa vraie voie.
            </Typography>
            <Typography>
              Gires ne s'est pas arrêté, il a continué à se battre
            </Typography>
            <Typography>
              De la photographie au service de location des voitures, il faut
              dire que les débuts ont été tumultueux, les diificultés étaient
              énormes. Mais il fallait arriver par consentir des sacrifices dans
              une ville où entreprendre où trouver un très bon travail n'est
              jamais chose facile.
            </Typography>
            <Typography>
              Comnme qui dirait que "C'est en forgeant qu'on devient forgeron",
              Gires Kamgang réussi par trouver son envol avec Safari Car,
              entreprise à la tête de laquelle il cordonne avec bravoure et
              abnégation les services de location des voitures luxueuses à
              Kinshasa. À son actif, plusieurs opérations réussies, qu'on ne
              peut compter, avec un carnet d'adresses prêt à éblouir tout
              entendement.
            </Typography>
            <Typography>
              Avec affirmation, les jours qui promettent d'être encore
              meilleurs. Plus Safari Car s'impose dans ce marché plus Giresse
              deviendra un modèle qu'on présentera plus sûrement!
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </main>
  );
};

export default About;
