import { Box, Container, Grid, Typography, useTheme } from "@mui/material";
import React from "react";

const HomeValues = () => {
  const theme = useTheme();
  return (
    <Box sx={{ backgroundColor: "#eee", p: 5 }}>
      <Container>
        <Typography
          sx={{ my: 5 }}
          color={"primary"}
          textAlign={"center"}
          variant="h3"
        >
          <span style={{ color: theme.palette.secondary.main }}>Nos</span>{" "}
          valeurs
        </Typography>
        <Grid container spacing={2}>
          <Grid
            container
            direction={"row"}
            justifyContent={"center"}
            alignItems={"center"}
            item
            xs={12}
            md={6}
          >
            <Typography textAlign={"center"}>
              <img src="/images/carValues.png" alt="" width={"100%"} />
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography textAlign={"center"}>
              <img src="/images/values.png" alt="" width={"100%"} />
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default HomeValues;
