export const menuList = [
  {
    name: "Accueil",
    href: "/",
  },
  {
    name: "Catalogue",
    href: "https://firebasestorage.googleapis.com/v0/b/safari-car.appspot.com/o/rental%2FCatalogue%20Safari%20car%202024.pdf?alt=media&token=cee369cd-926c-4e73-a7d3-db3b9a426e6a",
  },
  {
    name: "Le parcours",
    href: "/a-propos",
  },
];
