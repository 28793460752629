import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import { footerData, informations } from "../../data";

const Footer = () => {
  const theme = useTheme();

  return (
    <Box sx={{ backgroundColor: theme.palette.secondary.main, py: 3 }}>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <Typography sx={{ display: { xs: "none", md: "flex" } }}>
              <img src={"/images/Logo-white.png"} alt="logo" width={"70%"} />
            </Typography>
            <Typography
              textAlign={"center"}
              sx={{ display: { xs: "flex", md: "none" } }}
            >
              <img src={"/images/Logo-white.png"} alt="logo" width="30%" />
            </Typography>
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography color={"primary"} variant="h6">
              Safari Car
            </Typography>
            {informations.map((item) => (
              <Button
                component="a"
                href={item.href}
                sx={{
                  color: "white",
                  fontSize: 17,
                  display: "block",
                  p: 0,
                  "&:hover": {
                    color: "white",
                  },
                }}
              >
                {item.title}
              </Button>
            ))}
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography color={"primary"} variant="h6">
              Contact
            </Typography>
            <Typography sx={{ color: "white" }}>
              8884, Immeuble Jonya Tower <br />
              Référence Station Cobil Gombe <br />
              Derrière EquityBCDC <br />
              C/ Gombe <br />
            </Typography>
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography color={"primary"} variant="h6">
              Suivez-nous
            </Typography>
            <Box>
              {footerData.communnity.socials.map((social) => (
                <IconButton
                  component="a"
                  href={social.link}
                  sx={{
                    "&:hover": {
                      color: theme.palette.secondary.main,
                      fontSize: 25,
                    },
                  }}
                >
                  {social.icon}
                </IconButton>
              ))}
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Divider />
            <Typography
              textAlign="center"
              color={"white"}
              sx={{
                fontSize: 13,
                my: 2,
              }}
            >
              Copyright &copy; {new Date().getFullYear()}, SafariCar. Tous
              droits réservés.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Footer;
