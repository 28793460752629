import { Box, Container, Typography, useTheme } from "@mui/material";
import React from "react";
import Slider from "react-slick";

const HomePartners = () => {
  const theme = useTheme();
  const settings = {
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
  };
  return (
    <Container sx={{ p: 5 }}>
      <Typography
        color={"primary"}
        sx={{ my: 5 }}
        textAlign={"center"}
        variant="h3"
      >
        <span style={{ color: theme.palette.secondary.main }}>
          Ils nous ont
        </span>{" "}
        fait confiance
      </Typography>

      <Box sx={{ my: 5 }}>
        <Slider {...settings}>
          <Typography>
            <img src="/images/compassionLogo.jpg" alt="" width={"60%"} />
          </Typography>
          <Typography>
            <img src="/images/sultaniLogo.jpg" alt="" width={"60%"} />
          </Typography>
          <Typography>
            <img src="/images/afrilandLogo.jpg" alt="" width={"60%"} />
          </Typography>
          <Typography>
            <img src="/images/francophonieLogo.jpg" alt="" width={"60%"} />
          </Typography>
          <Typography>
            <img src="/images/20Logo.jpg" alt="" width={"60%"} />
          </Typography>
          <Typography>
            <img src="/images/bccLogo.jpg" alt="" width={"60%"} />
          </Typography>
        </Slider>
      </Box>
    </Container>
  );
};

export default HomePartners;
