import { Container, Grid, Typography, useTheme } from "@mui/material";
import React from "react";

const HomeTypes = () => {
  const theme = useTheme();
  return (
    <Container>
      <Grid sx={{ my: 5, p: 5, backgroundColor: "#666" }} container spacing={2}>
        <Grid
          container
          direction={"row"}
          justifyContent={"center"}
          alignItems={"center"}
          item
          xs={12}
          md={6}
        >
          <Typography color={"white"} variant="h3">
            Types de{" "}
            <span style={{ color: theme.palette.primary.main }}>voitures</span>
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography textAlign={"center"}>
            <img src="/images/types.jpg" alt="" width={"100%"} />
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

export default HomeTypes;
