import { alpha, Container, Grid, Typography, useTheme } from "@mui/material";
import React from "react";

const CatalogueBanner = () => {
  const theme = useTheme();

  return (
    <Grid
      container
      spacing={2}
      sx={{
        p: { md: 8, xs: 2 },
        pb: 10,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundImage: `linear-gradient(
        to bottom,
         ${alpha(theme.palette.secondary.main, 0.7)},
         ${alpha(theme.palette.primary.main, 0.2)}
      ), url('/images/banner.JPG')`,
        height: 300,
      }}
    >
      <Grid
        container
        direction={"row"}
        justifyContent={"center"}
        alignItems={"center"}
        item
        sx={{ color: "white" }}
        xs={12}
        md={6}
      >
        <Container>
          <Typography
            variant={"h3"}
            textAlign={"center"}
            sx={{ mt: 8, fontSize: { xs: 40 }, textTransform: "uppercase" }}
          >
            Le Catalogue
          </Typography>
        </Container>
      </Grid>
    </Grid>
  );
};

export default CatalogueBanner;
