import React from "react";
import CatalogueBanner from "../../components/catalogue/Banner";
import Car from "../../components/catalogue/Product";
import { Grid, Pagination, Typography, useTheme } from "@mui/material";
import SearchBar from "../../components/includes/SearchBar";
import { useState } from "react";
import { useEffect } from "react";
import {
  collection,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { db } from "../../firebase";

const Catalogue = () => {
  const theme = useTheme();
  const [cars, setCars] = useState([]);
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const itemsPerPage = 12;
  const [itemOffset, setItemOffset] = useState(0);

  const endOffset = itemOffset + itemsPerPage;
  const currentItems = search ? data : data.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(data.length / itemsPerPage);

  useEffect(() => {
    const colRef = collection(db, "cars");
    const q = query(
      colRef,
      where("inStock", "==", true),
      orderBy("price", "asc")
    );

    const unsubscribe = onSnapshot(q, (snapshot) => {
      let content = [];
      if (snapshot.docs) {
        snapshot.docs.forEach((doc) =>
          content.push({ id: doc.id, ...doc.data() })
        );
        setCars(content);
        setData(content);
      }
    });

    // Unsubscribe from events when no longer in use
    return () => unsubscribe();
  }, []);

  const searchFilter = (text) => {
    const newData =
      cars &&
      cars.filter((item) => {
        const itemData = item.name ? item.name.toUpperCase() : "".toUpperCase();
        const textData = text.toUpperCase();
        return itemData.indexOf(textData) > -1;
      });
    setSearch(text);
    setData(newData);
    setItemOffset(0);
  };

  const handlePageClick = (event, value) => {
    const newOffset = (value * itemsPerPage) % data.length;
    setItemOffset(newOffset);
  };
  return (
    <main>
      <CatalogueBanner />

      <Grid container sx={{ p: 8, backgroundColor: "#eee" }} spacing={2}>
        <Grid item xs={12}>
          <SearchBar
            searchFilter={searchFilter}
            placeholder={"Ex: TOYOTA IST, Suzuki Swift..."}
          />
        </Grid>
        {currentItems && currentItems.map((car) => <Car data={car} />)}
        <Grid
          container
          direction={"row"}
          justifyContent={"center"}
          sx={{ mt: 2 }}
          item
          xs={12}
        >
          <Pagination
            variant="outlined"
            color="primary"
            onChange={handlePageClick}
            count={pageCount}
          />
        </Grid>
      </Grid>
    </main>
  );
};

export default Catalogue;
