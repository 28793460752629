import React from "react";
import { Box, Container, Grid, Typography, useTheme } from "@mui/material";

const HomeAbout = () => {
  const theme = useTheme();
  return (
    <Container sx={{ p: 3, my: 5 }}>
      <Grid container direction={"row"} spacing={2}>
        <Grid
          container
          direction={"row"}
          justifyContent={"center"}
          alignItems={"center"}
          item
          xs={12}
          md={6}
        >
          <Box>
            <Typography color={"primary"} variant="h3">
              Qui{" "}
              <span style={{ color: theme.palette.secondary.main }}>
                sommes-nous
              </span>{" "}
              ?
            </Typography>
            <Typography sx={{ my: 2 }}>
              Safari Car, nous existions depuis le 4 Novembre 2020. <br />
              Nous sommes la première agence de référence à Kinshasa qui vous
              offre les meilleures voitures luxueuses à des prix hors
              concurrence.
            </Typography>
          </Box>
        </Grid>
        <Grid item sx={12} md={6}>
          <Typography textAlign={"center"}>
            <img src="/images/about.png" alt="banner" width={"100%"} />
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

export default HomeAbout;
