import { Box, Container, Grid, Typography, useTheme } from "@mui/material";
import React from "react";
import CountUp from "react-countup";

const HomeRealisations = () => {
  const theme = useTheme();
  return (
    <Container sx={{ p: 5 }}>
      <Typography textAlign={"center"} variant="h3" color={"primary"}>
        <span style={{ color: theme.palette.secondary.main }}>Nos</span>{" "}
        réalisations
      </Typography>
      <Grid sx={{ my: 5 }} container spacing={2}>
        <Grid item xs={12} md={3}>
          <Box
            sx={{
              borderWidth: 20,
              borderColor: theme.palette.primary.main,
              borderStyle: "outset",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              p: 8,
              borderRadius: 150,
            }}
          >
            <Box>
              <Typography textAlign={"center"} variant="h4" color={"primary"}>
                <CountUp separator="" prefix="+" end={1000} duration={20} />
              </Typography>
              <Typography variant="h5" textAlign={"center"} color={"secondary"}>
                clients satisfaits
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={3}>
          <Box
            sx={{
              borderWidth: 20,
              borderColor: theme.palette.primary.main,
              borderStyle: "outset",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              p: 8,
              borderRadius: 100,
            }}
          >
            <Box>
              <Typography textAlign={"center"} variant="h4" color={"primary"}>
                <CountUp separator="" prefix="+" end={7000} duration={20} />
              </Typography>
              <Typography variant="h5" textAlign={"center"} color={"secondary"}>
                locations réussies
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={3}>
          <Box
            sx={{
              borderWidth: 20,
              borderColor: theme.palette.primary.main,
              borderStyle: "outset",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              p: 8,
              borderRadius: 100,
            }}
          >
            <Box>
              <Typography textAlign={"center"} variant="h4" color={"primary"}>
                <CountUp separator="" prefix="+" end={4000} duration={20} />
              </Typography>
              <Typography
                variant="h5"
                fontSize={17}
                textAlign={"center"}
                color={"secondary"}
              >
                followers sur les réseaux sociaux
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={3}>
          <Box
            sx={{
              borderWidth: 20,
              borderColor: theme.palette.primary.main,
              borderStyle: "outset",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              p: 8,
              borderRadius: 100,
            }}
          >
            <Box>
              <Typography textAlign={"center"} variant="h4" color={"primary"}>
                <CountUp separator="" prefix="+" end={7000} duration={20} />
              </Typography>
              <Typography variant="h5" textAlign={"center"} color={"secondary"}>
                demandes réçues
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default HomeRealisations;
