import { Box, Button, Grid, Typography, alpha, useTheme } from "@mui/material";
import React, { Fragment } from "react";

const Car = ({ data }) => {
  const theme = useTheme();

  return (
    <Fragment>
      <Grid item xs={12} md={3}>
        <Box
          sx={{
            "&:hover": {
              borderWidth: 1,
              borderColor: theme.palette.primary.main,
            },
          }}
        >
          <Box
            sx={{
              height: 200,
              px: { md: 10, xs: 6 },
              pt: { xs: 6, md: false },
              overflow: "hidden",
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundImage: `linear-gradient(
        to bottom,
         ${alpha(theme.palette.primary.main, 0.0)},
         ${alpha("#000", 0.0)}
      ), url(${data.image})`,
            }}
          ></Box>
          <Box sx={{ p: 3 }}>
            <Typography color={"text.primary"} variant="h6">
              {data.name}{" "}
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignContent: "center",
              }}
            >
              <Typography
                textAlign={"center"}
                sx={{
                  mt: 1,
                  backgroundColor: theme.palette.primary.main,
                  color: "white",
                  p: 0.5,
                  width: "40%",
                  borderRadius: 1,
                  fontSize: 15,
                }}
              >
                {data.discountedPrice} USD/jour
              </Typography>
              <Box>
                <Button
                  sx={{ mt: 1, borderRadius: 10 }}
                  variant="outlined"
                  color="inherit"
                  component="a"
                  href={"tel:"}
                >
                  Réserver
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Grid>
    </Fragment>
  );
};

export default Car;
