import { Box, Container, Grid, Typography, useTheme } from "@mui/material";
import React from "react";
import { useSpring, animated } from "@react-spring/web";

const HomeServices = () => {
  const theme = useTheme();
  const spring = useSpring({
    from: { x: -100 },
    to: { x: 0 },
  });

  return (
    <Container sx={{ p: 3 }}>
      <Typography textAlign={"center"} color={"primary"} variant="h3">
        <span style={{ color: theme.palette.secondary.main }}>Ce que nous</span>{" "}
        faisons{" "}
      </Typography>
      <Typography variant="h5" color={"primary"} textAlign={"center"}>
        Nos services
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Typography>
            <img src="/images/services.jpg" alt="" width={"100%"} />
          </Typography>
        </Grid>
        <Grid
          container
          direction={"row"}
          justifyContent={"center"}
          alignItems={"center"}
          item
          xs={12}
          md={6}
        >
          <Box sx={{ backgroundColor: theme.palette.primary.main, p: 5 }}>
            <Typography color={"white"}>
              À travers notre gamme d'automobiles très variées, nous mettons à
              disposition du public des modèles de voitures à haute facture,
              pour repondre à vos besoins de mobilités dans les différents
              situations.
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid sx={{ my: 3 }} container spacing={2}>
        <Grid item xs={12} md={6}>
          <animated.div style={{ ...spring }}>
            <img src="/images/s1.png" alt="" width={"100%"} />
          </animated.div>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography>
            <img src="/images/s2.png" alt="" width={"100%"} />
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <animated.div style={{ ...spring }}>
            <img src="/images/s3.png" alt="" width={"100%"} />
          </animated.div>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography>
            <img src="/images/s4.png" alt="" width={"100%"} />
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

export default HomeServices;
