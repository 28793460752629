import React from "react";
import HomeBanner from "../../components/home/Banner";
import HomeAbout from "../../components/home/About";
import HomeServices from "../../components/home/Services";
import HomeValues from "../../components/home/Values";
import HomeTypes from "../../components/home/Types";
import HomeRealisations from "../../components/home/Realisations";
import HomePartners from "../../components/home/Partners";

const Home = () => {
  return (
    <main>
      <HomeBanner />
      <HomeAbout />
      <HomeServices />
      <HomeValues />
      <HomeTypes />
      <HomeRealisations />
      <HomePartners />
    </main>
  );
};

export default Home;
